//
import PProviderService from '@/services/api/Provider/PProvider'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-provider-partners',
  data() {
    return {
      busy: {
        create: false,
        delete: false,
        listed: false,
        update: false,   
      },
      dialogs: {
        create: false,
        delete: false,
        update: false,
      },
      filters: {
        limit: 15,
        page: 1,
      },
      pProviderPartnerCreate: {},
      pProviderPartnerDelete: {},
      pProviderPartnerStored: {},
      pProviderPartnerUpdate: {},
      pProviderPartners: {
        data: [],
        last_page: 1,
      },
    }
  },
  methods: {

    /**
     * 
     */
    handleCreateReset() {
      this.pProviderPartnerCreate = PProviderService.toCreatePartner()
    },

    /**
     * 
     */
    async handleCreateSubmit() {
      this.busy.create = true
      await PProviderService.createPartner(this.$provider.id, this.pProviderPartnerCreate).then(this.handleCreateSuccess).catch(this.handleError)
      this.busy.create = false
    },

    /**
     * 
     */
    handleCreateSuccess(payload) {
      this.handleReset()
      this.handleCreateReset()
      PProviderService.translate({ response: payload }).then(this.$toast.success)
      this.dialogs.create  = false
    },

    /**
     * 
     */
    handleDeleteClick(pProviderPartner) {
      this.dialogs.delete   = true
      this.pProviderPartnerDelete  = pProviderPartner
    },

    /**
     * 
     */
    async handleDeleteSubmit() {
      this.busy.delete = true
      await PProviderService.deletePartner(this.$provider.id, this.pProviderPartnerDelete).then(this.handleDeleteSuccess).catch(this.handleError)
      this.busy.delete = false
    },

    /**
     * 
     */
    handleDeleteSuccess() {
      this.handleReset()
      this.dialogs.delete = false
    },

    /**
     * 
     */
    async handleReset() {
      this.busy.listed = true
      this.pProviderPartners = await PProviderService.getPartnersByProviderId(this.$provider.id)
      //console.log(this.pProviderPartners)
      this.busy.listed = false
    },

    /**
     * 
     */
    handleUpdateClick(pProviderPartner) {
      this.dialogs.update   = true
      this.pProviderPartnerUpdate  = { ...pProviderPartner }
    },

    /**
     * 
     */
    handleUpdateReset() {
      this.pProviderPartnerUpdate  = { ...this.pProviderPartnerStored }
    },

    /**
     * 
     */
    async handleUpdateSubmit() {
      this.busy.update = true
      await PProviderService.updatePartner(this.$provider.id, this.pProviderPartnerUpdate).then(this.handleUpdateSuccess).catch(this.handleError)
      this.busy.update = false
    },

    /**
     * 
     */
    handleUpdateSuccess(payload) {
      PProviderService.translate({ response: payload }).then(this.$toast.success)
      this.handleReset()
    },

    /**
     * 
     */
    handleError(payload) {
      PProviderService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleRepeatPasswordRule() {
      return this.pProviderPartnerCreate.has_equal_passwords || 'Las contraseñas deben ser las mismas.'
    },
  },
  created() {
    this.handleReset()
  },
  props: {

    /**
     * 
     */
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(200),
      ],
    },

    /**
     * 
     */
    participationPercentageRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.numeric,
      ],
    },

    /**
     * 
     */
    rutRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.rut,
        RulesService.max(12),
      ],
    },
  },
  watch: {

    /**
     * 
     */
    'filters.page'() {
      this.handleReset()
    },

    /**
     * 
     */
    'filters.limit'() {
      this.handleReset()
    },
  },
}